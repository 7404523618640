import React from "react"

import { Helmet } from "react-helmet"

import Layout from "../components/layout"
import { Container, Row, Col } from "react-bootstrap"

const NotFoundPage = () => {
  return (
    <>
      <Helmet bodyAttributes={{ class: "short" }} />
      <Layout>
        <Container fluid className="info">
          <Container className="info-contain">
            <Row>
              <Col md={12}>
                <h4 className="info-title">Not Found</h4>
              </Col>
              <Col md={12}>
                <p className="info-text-large">
                  You just hit a route that doesn&#39;t exist.
                </p>
              </Col>
            </Row>
          </Container>
        </Container>
      </Layout>
    </>
  )
}

export default NotFoundPage
